<script setup lang="ts">
const title = 'Powered by Data'
const description = 'Deep insights require expansive, reliable, and structured data.'
const stats = [
    { id: 1, name: 'Bills', value: '1M+' },
    { id: 2, name: 'Public officials', value: '20K+' },
    { id: 3, name: 'Geographies mapped', value: '69K' },
]
</script>

<template>
    <div class="bg-green-900 py-24 sm:py-32">
        <div class="mx-auto max-w-7xl px-6 lg:px-8">
            <div class="mx-auto max-w-2xl lg:max-w-none">
                <div class="text-center">
                    <h2 class="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                        {{ title }}
                    </h2>

                    <p class="mt-4 text-lg leading-8 text-green-300">
                        {{ description }}
                    </p>
                </div>

                <dl
                    class="mt-16 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-3"
                >
                    <div
                        v-for="stat in stats"
                        :key="stat.id"
                        class="flex flex-col bg-white/5 p-8"
                    >
                        <dt class="text-sm font-semibold leading-6 text-green-300">
                            {{ stat.name }}
                        </dt>

                        <dd class="order-first text-3xl font-semibold tracking-tight text-white">
                            {{ stat.value }}
                        </dd>
                    </div>
                </dl>
            </div>
        </div>
    </div>
</template>
